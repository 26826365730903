<template>
  <section class="container mt-3">
    <h4 class="font-weight-normal">Sub kategoriya</h4>
    <p v-if="inValid" :class="{ 'text-danger': inValid }">
      Iltimos bo'sh o'rinlarni barchasini to'diring
    </p>
    <a-row
      v-for="(sub, index) in arraySub"
      :key="index"
      type="flex"
      justify="space-between"
    >
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="nomi" class="mb-0 mt-2">Nomi</label>
        <a-input
          placeholder="nomi"
          v-model.trim="sub.name"
          @change="changeHandler"
        />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="nomi-eng" class="mb-0 mt-2">Nomi [eng]</label>
        <a-input
          placeholder="eng"
          v-model.trim="sub.name_en"
          @change="changeHandler"
        />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="nomi-rus" class="mb-0 mt-2">Nomi [rus]</label>
        <a-input
          placeholder="rus"
          v-model.trim="sub.name_ru"
          @change="changeHandler"
        />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="nomi-uz" class="mb-0 mt-2">Nomi [uz]</label>
        <a-input
          placeholder="uz"
          v-model.trim="sub.name_uz"
          @change="changeHandler"
        />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 2 }">
        <label for="nomi-uz" class="mb-0 mt-2">Order</label>
        <a-input
          type="number"
          min="1"
          v-model.trim="sub.order"
          @change="changeHandler"
        />
      </a-col>
      <a-col :span="4">
        <label for="nomi-uz" class="mb-0 mt-2">O'chirish</label>
        <div>
          <a-button class="bg-danger text-white" @click="deleteSub(index)"
            >O'chirish</a-button
          >
        </div>
      </a-col>
    </a-row>
    <a-button type="primary" @click="addSub" style="margin-top:5px;">
      Qo'shish
    </a-button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      data: {
        name: '',
        name_en: '',
        name_ru: '',
        name_uz: '',
        order: 1,
        main: false,
      },
      arraySub: [
        {
          ...this.data,
        },
      ],
      inValid: false,
    };
  },

  methods: {
    addSub() {
      if (this.arraySub.length == 0) {
        this.index++;
        this.arraySub.push({ ...this.data });
      } else {
        const { name, name_en, name_ru, name_uz } = this.arraySub[this.index];

        if (!name || !name_en || !name_ru || !name_uz) {
          this.inValid = true;
          return;
        }
        let obj = {
          name: '',
          name_en: '',
          name_ru: '',
          name_uz: '',
          order: 1,
          main: false,
        };

        this.arraySub.push(obj);
        this.inValid = false;
        this.index++;
        this.$emit('sub-category', this.arraySub);
      }
    },
    changeHandler() {
      this.$emit('sub-category', this.arraySub);
    },

    deleteSub(id) {
      this.index--;
      this.arraySub.splice(id, 1);
    },
  },
};
</script>
