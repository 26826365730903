<template>
  <div class="container mt-3">
    <h4 class="font-weight-normal">Asosiy kategoriya</h4>
    <a-row>
      <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
        <form>
          <div>
            <label for="nomi" :class="{ inValidLabel: name.invalid }"
              >Nomi</label
            >
            <a-input
              id="name"
              :class="{ invalidInput: name.invalid }"
              @change="changeHandler"
              @blur="nomiValidate"
            />
          </div>
          <div>
            <label for="nomi" :class="{ inValidLabel: name_en.invalid }"
              >Nomi [eng]</label
            >
            <a-input
              placeholder="eng"
              id="name_en"
              @change="changeHandler"
              :class="{ invalidInput: name_en.invalid }"
              @blur="nomiValidate"
            />
          </div>
          <div>
            <label for="nomi-rus" :class="{ inValidLabel: name_ru.invalid }"
              >Nomi [rus]</label
            >
            <a-input
              placeholder="rus"
              id="name_ru"
              @change="changeHandler"
              :class="{ invalidInput: name_ru.invalid }"
              @blur="nomiValidate"
            />
          </div>
          <div>
            <label for="nomi-uz" :class="{ inValidLabel: name_uz.invalid }"
              >Nomi [uz]</label
            >
            <a-input
              placeholder="uz"
              id="name_uz"
              @change="changeHandler"
              :class="{ invalidInput: name_uz.invalid }"
              @blur="nomiValidate"
            />
          </div>
          <div>
            <label>Order</label>
            <a-input type="number" min="1" v-model="order.val" />
          </div>
          <div>
            <a-checkbox
              name="type"
              id="main"
              class="mt-2"
              @change="checkHandler"
            >
              Main
            </a-checkbox>
          </div>
        </form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: ['editItem'],
  data() {
    return {
      name: {
        val: '',
        invalid: false,
      },
      name_en: {
        val: '',
        invalid: false,
      },
      name_ru: {
        val: '',
        invalid: false,
      },
      name_uz: {
        val: '',
        invalid: false,
      },
      main: {
        val: false,
        invalid: false,
      },
      order: {
        val: 1,
        invalid: false,
      },
    };
  },

  methods: {
    nomiValidate(e) {
      if (this[e.target.id].val === '') {
        this[e.target.id].invalid = true;
      }
    },

    changeHandler(e) {
      this[e.target.id].invalid = false;
      this[e.target.id].val = e.target.value;

      let obj = {
        name: this.name,
        name_en: this.name_en,
        name_ru: this.name_ru,
        name_uz: this.name_uz,
        order: this.order,
        main: this.main,
      };

      this.$emit('change-handler', obj);
    },

    checkHandler(e) {
      this.main.val = e.target.checked;
      let obj = {
        name: this.name,
        name_en: this.name_en,
        name_ru: this.name_ru,
        name_uz: this.name_uz,
        order: this.order,
        main: this.main,
      };

      this.$emit('change-handler', obj);
    },
  },
};
</script>

<style scoped>
.invalidInput {
  border-color: red;
}

.inValidLabel {
  color: red;
}
</style>
