<template>
  <section>
    <a-row>
      <new-category @change-handler="newCategory"></new-category>
    </a-row>
    <a-row>
      <new-sub-category @sub-category="subCategoryHandler"></new-sub-category>
    </a-row>

    <a-row type="flex" justify="end" class="mb-5">
      <a-button
        type="primary"
        size="large"
        class="bg-success mt-5 mr-5"
        @click="createCategory"
      >
        Qo'shish
      </a-button>
    </a-row>
    <modal v-if="inValid" @close="closeModal" title="Error! Bo'm-bosh inputlar">
      <p class="text-white bg-danger p-3 ">
        Iltimos bosh o'rinlarni hammasini to'ldiring
      </p>
    </modal>
  </section>
</template>

<script>
import NewCategory from '../../components/CategoryCom/NewCategory.vue';
import NewSubCategory from '../../components/SubCategory/NewSubCategory.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    NewCategory,
    NewSubCategory,
  },

  data() {
    return {
      parentCategory: '',
      subChildrenCategory: [],
      inValid: false,
    };
  },

  methods: {
    ...mapActions({
      postCategory: 'category/postCategory',
    }),
    newCategory(data) {
      this.parentCategory = data;
    },

    subCategoryHandler(data) {
      this.subChildrenCategory = data;
    },
    validate(data, i) {
      if (i === 'main') {
        return false;
      }
      return !data.val ? true : false;
    },
    powerFulvalidate(data) {
      let err;
      outer: for (let i of data) {
        let arr = Object.keys(i);
        if (arr.length < 5 && arr.length >= 1) {
          err = 'error';
          break outer;
        }
        for (let j in i) {
          if (i[j] === '') {
            err = 'error';
            break outer;
          }
        }
      }
      return err ? true : false;
    },

    createCategory() {
      if (!this.parentCategory) {
        this.inValid = true;
        return;
      }
      let parentCategory = this.parentCategory;
      const { name, name_ru, name_en, name_uz } = parentCategory;
      if (
        this.subChildrenCategory.length > 0 &&
        this.powerFulvalidate(this.subChildrenCategory)
      ) {
        this.inValid = true;
        return;
      }
      if (!name || !name_ru || !name_uz || !name_en) {
        this.inValid = true;
        return;
      }

      const {
        name: parentName,
        name_en: parentName_en,
        name_ru: parentName_ru,
        name_uz: parentName_uz,
        main,
        order,
      } = this.parentCategory;

      let obj = {
        name: parentName.val,
        name_en: parentName_en.val,
        name_ru: parentName_ru.val,
        name_uz: parentName_uz.val,
        order: order.val,
        main: main.val,
        children: [...this.subChildrenCategory],
      };

      this.postCategory(obj)
        .then(() => {
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Category qo'shildi :)",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Categoriya' });
          }, 1000);
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            duration: 13,
            description:
              "Sub kategorya bilan asosiy kategoriya bir xil bo'lganligi sabab sub kategoriya qo'shilmadi.Iltimos o'zgartirish bo'limdan hozirgi asosiy categoryani tanlab sub kategoryani qayta kiriting.",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Categoriya' });
          }, 1000);
        });
    },

    closeModal(bool) {
      this.inValid = bool;
    },
  },
};
</script>
